import Guess from "../components/Guess";
import { Word } from "../words/Word";
import { useContext, useEffect, useState } from "react";
import { SampleContext, SocketContext } from "../context/socket";

type LobbyProps = {
	lobbyId: string;
	playerList: string[];
	isHost: boolean;
	onGameStart: (players: string[]) => void;
};

function Lobby({ lobbyId, playerList, isHost, onGameStart }: LobbyProps) {
	const socket = useContext(SocketContext);
	const samplePlayer = useContext(SampleContext);

	const [players, setPlayers] = useState<Word[]>(
		playerList.map((player) => Word.fromString(player))
	);
	const [timer, setTimer] = useState<number>(-1);

	useEffect(() => {
		socket.on("playerJoinedYourLobby", (newUsername, lobbyPlayers) => {
			setPlayers(lobbyPlayers.map((player) => Word.fromString(player)));
		});

		socket.on("playerLeftYourLobby", (username, lobbyPlayers) => {
			setPlayers(lobbyPlayers.map((player) => Word.fromString(player)));
		});

		socket.on("gameStarting", (timer) => {
			setTimer(timer);
			if (timer === 3) {
				samplePlayer.getCountin().play();
			} else if (timer === 1) {
				setTimeout(
					() =>
						onGameStart(players.map((player) => player.getWord())),
					1000
				);
			}
		});

		return function cleanup() {
			socket.off("playerJoinedYourLobby");
			socket.off("playerLeftYourLobby");
			socket.off("gameStarting");
		};
	});

	function startGame() {
		socket.emit("startGame");
	}

	return (
		<div className="max-w-lg mx-auto p-2">
			<div className="pb-3">
				<Guess word={Word.fromString(lobbyId)} />
			</div>
			<hr />
			<div>
				<h6 className="pb-3">Players</h6>
				{players.map((word) => (
					<div key={word.getWord()} className="pb-2">
						<Guess word={word} />
					</div>
				))}
			</div>
			{timer > 0 && <p>Game starting in {timer}</p>}
			{isHost && (
				<button
					onClick={startGame}
					disabled={timer > 0}
					className="disabled:text-gray-600"
				>
					Start Game
				</button>
			)}
		</div>
	);
}

export default Lobby;
