import { Character } from "./characters.interface";
import { GuessResult } from "../../../app/types/SocketTypes";

import { EventEmitter } from "fbemitter";

export class Word {
	characters: Character[];
	maxLength: number;
	reveal: EventEmitter;

	constructor(
		characters: Character[] = [],
		maxLength: number = 5,
		reveal = new EventEmitter()
	) {
		this.characters = characters;
		this.maxLength = maxLength;
		this.reveal = reveal;
	}

	length() {
		return this.characters.length;
	}

	isFull() {
		return this.characters.length === this.maxLength;
	}

	getWord() {
		return this.characters
			.map((character) => character.letter)
			.join("")
			.toUpperCase();
	}

	addLetter(letter: string) {
		if (this.characters.length < this.maxLength) {
			this.characters.push({ letter: letter, status: "UNKNOWN" });
		}
	}

	deleteLetter() {
		this.characters.pop();
	}

	updateWithStatus(status: GuessResult) {
		for (let i = 0; i < status.length; i++) {
			if (this.characters.length > i) {
				this.characters[i].status = status[i];
			} else if (this.maxLength > i) {
				// This is to handle when we get results for other players
				// They won't have characters in their words, so we add fake ones
				this.characters.push({ letter: "", status: status[i] });
			}
		}
		this.reveal.emit("reveal");
	}

	clone(): Word {
		return new Word(this.characters, this.maxLength, this.reveal);
	}

	static fromString(string: string) {
		const chars: Character[] = string.split("").map((letter) => {
			return { letter: letter, status: "UNKNOWN" };
		});
		return new Word(chars, Math.max(chars.length, 5));
	}
}
