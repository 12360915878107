export const letterColorMap: { [key: string]: string } = {
	GREY: "bg-wrong text-known-letter",
	GREEN: "bg-correct text-known-letter",
	YELLOW: "bg-close text-known-letter",
	UNKNOWN: "bg-unknown text-unknown-letter",
};

export const keyBoardColorMap: { [key: string]: string } = {
	GREY: "bg-wrong text-known-letter",
	GREEN: "bg-correct text-known-letter",
	YELLOW: "bg-close text-known-letter",
	UNKNOWN: "bg-unknown text-unknown-letter",
};
