import { io, Socket } from "socket.io-client";
import React from "react";
import { ClientEvent, ServerEvent } from "../../../app/types/SocketTypes";
import { SamplePlayer } from "../util/SamplePlayer";

export const socket: Socket<ServerEvent, ClientEvent> = io({
	reconnection: false,
	closeOnBeforeunload: false,
});
export const SocketContext = React.createContext(socket);

export const samplePlayer: SamplePlayer = new SamplePlayer();
export const SampleContext = React.createContext(samplePlayer);
