import { Howl, Howler } from "howler";

export class SamplePlayer {
	private missSamples: Howl[] = [];
	private closeSamples: Howl[] = [];
	private hitSamples: Howl[] = [];
	private countin: Howl = new Howl({
		src: [SamplePlayer.getSamplePath("countin")],
	});
	private minuteTicker: Howl = new Howl({
		src: [SamplePlayer.getSamplePath("minute-ticker")],
	});

	constructor() {
		for (let i of [1, 2]) {
			this.missSamples.push(
				new Howl({
					src: [SamplePlayer.getSamplePath(`castanet-${i}`)],
				})
			);
		}

		for (let i = 1; i <= 10; i++) {
			this.closeSamples.push(
				new Howl({
					src: [SamplePlayer.getSamplePath(`marimba-${i}`)],
				})
			);

			this.hitSamples.push(
				new Howl({
					src: [SamplePlayer.getSamplePath(`glock-${i}`)],
				})
			);
		}
	}

	getRandomMissSample(): Howl {
		return this.missSamples[Math.floor(Math.random() * 1.7)];
	}

	getCloseSample(position: [number, number]) {
		return this.closeSamples[position[0] + position[1]];
	}

	getHitSample(position: [number, number]) {
		return this.hitSamples[position[0] + position[1]];
	}

	getCountin() {
		return this.countin;
	}

	getMinuteTicker() {
		return this.minuteTicker;
	}

	enableSound() {
		Howler.mute(false);
	}

	disableSound() {
		Howler.mute(true);
	}

	private static getSamplePath(sampleName: string) {
		return process.env.PUBLIC_URL + `/samples/${sampleName}.wav`;
	}
}
