export function isInvalidKey(
	e: KeyboardEvent,
	acceptNumbers: boolean = true,
	acceptEnter: boolean = false
): boolean {
	const lowerCaseKey = e.key.length === 1 ? e.key.toLowerCase() : e.key;

	const isLetter =
		e.key.length === 1 && lowerCaseKey >= "a" && lowerCaseKey <= "z";
	const isNumber = e.key >= "0" && e.key <= "9";
	const isDelete = e.key === "Backspace" || e.key === "Delete";
	const isEnter = e.key === "Enter";

	const invalidKey =
		!isDelete &&
		!isLetter &&
		(!acceptNumbers || !isNumber) &&
		(!acceptEnter || !isEnter);
	const usingCommand = (isLetter || isNumber) && (e.ctrlKey || e.altKey);
	return invalidKey || usingCommand;
}
