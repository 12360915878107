import {
	MutableRefObject,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import Letter from "./Letter";
import { SampleContext, SocketContext } from "../context/socket";

type Setting = {
	state: MutableRefObject<boolean>;
	label: string;
	propertyName: string;
	defaultValue: boolean;
	onEnable?: () => void;
	onDisable?: () => void;
};

function Header() {
	let [settingsOpen, setSettingsOpen] = useState<boolean>(false);
	let [errorOpen, setErrorOpen] = useState<boolean>(false);
	let [cantLabel, setCantLabel] = useState<string>("");
	let [playersOnline, setPlayersOnline] = useState<number>(0);
	let [sorryJordan, setSorryJordan] = useState<boolean>(false);

	let darkMode = useRef<boolean>(true);
	let colorBlindMode = useRef<boolean>(false);
	let funFont = useRef<boolean>(true);
	let whimsicalCant = useRef<boolean>(true);
	let sounds = useRef<boolean>(true);

	let rootElement = document.getElementsByTagName("html")[0];
	const samplePlayer = useContext(SampleContext);
	const socket = useContext(SocketContext);

	function getCantLabel() {
		if (cantLabel === "") {
			setCantLabel(
				["Whimsical cant", "Skeuomorphic skew", "Jaunty angle"][
					Math.floor(Math.random() * 3)
				]
			);
		}
		return cantLabel;
	}

	let settings: Setting[] = [
		{
			state: darkMode,
			label: "Dark mode",
			propertyName: "darkMode",
			defaultValue: true,
		},
		{
			state: colorBlindMode,
			label: "Colorblind mode",
			propertyName: "colorBlind",
			defaultValue: false,
		},
		{
			state: funFont,
			label: "Fun font",
			propertyName: "funFont",
			defaultValue: true,
		},
		{
			state: whimsicalCant,
			label: getCantLabel(),
			propertyName: "cant",
			defaultValue: true,
		},
		{
			state: sounds,
			label: "Sound",
			propertyName: "sounds",
			defaultValue: true,
			onEnable: () => {
				samplePlayer.enableSound();
			},
			onDisable: () => {
				samplePlayer.disableSound();
			},
		},
	];

	function setSetting(setting: Setting, value: boolean) {
		setting.state.current = value;
		localStorage.setItem(setting.propertyName, value.toString());
		rootElement.dataset[setting.propertyName] = value ? "on" : "off";
		if (value && setting.onEnable !== undefined) {
			setting.onEnable();
		} else if (setting.onDisable !== undefined) {
			setting.onDisable();
		}

		setSorryJordan(!sorryJordan);
	}

	useEffect(() => {
		for (let setting of settings) {
			let localStoragePreference = localStorage.getItem(
				setting.propertyName
			);
			if (localStoragePreference !== null) {
				setSetting(setting, localStoragePreference === "true");
			} else {
				setSetting(setting, setting.defaultValue);
			}
		}
	}, []);

	useEffect(() => {
		socket.on("playerCount", (count) => {
			setPlayersOnline(count);
		});

		socket.on("disconnect", () => {
			setErrorOpen(true);
		});

		socket.on("connect_error", () => {
			setErrorOpen(true);
		});

		socket.on("lobbyAbandoned", () => {
			setErrorOpen(true);
		});

		function disconnectSocket() {
			socket.disconnect();
		}

		window.addEventListener("beforeunload", disconnectSocket);

		return () => {
			socket.off("playerCount");
			socket.off("disconnect");
			socket.off("connect_error");
			socket.off("lobbyAbandoned");
			window.removeEventListener("beforeunload", disconnectSocket);
		};
	});

	return (
		<div
			className={
				"flex px-4 h-8 justify-center bg-gradient-to-b from-gray-900 to-gray-800/25 text-white content-center"
			}
		>
			<div className={"flex-1"}>
				{/*<button disabled={true}>Leave</button>*/}
				<span className="px-1 opacity-50">
					🟢 Players online: {playersOnline}
				</span>
			</div>
			<span>FRIENDLE</span>
			<div className={"flex-1 text-right"}>
				<button
					className={"px-1"}
					onClick={() => setSettingsOpen(true)}
				>
					<div
						className={`transition-transform ${
							settingsOpen ? "rotate-90" : "rotate-0"
						}`}
					>
						⚙
					</div>
				</button>
			</div>
			<div
				className={`fixed top-0 bottom-0 left-0 right-0 z-30 bg-gray-900 transition-opacity ${
					settingsOpen
						? "opacity-50"
						: "opacity-0 pointer-events-none"
				}`}
				onClick={() => setSettingsOpen(false)}
			/>

			<div
				className={`fixed top-0 bottom-0 left-0 right-0 z-50 flex content-center flex-col gap-5 justify-center transition p-5 ${
					errorOpen
						? "bg-gray-900/80 backdrop-blur-2xl"
						: "bg-transparent opacity-0 pointer-events-none"
				}`}
			>
				<span
					className={`text-2xl self-center transition-opacity
					${errorOpen ? "opacity-100" : "opacity-0"}
				`}
				>
					Sorry, you were disconnected from the server
				</span>
				<button onClick={() => window.location.reload()}>Reload</button>
			</div>

			<div
				className={`fixed z-40 h-[90%] w-[90%] bg-gray-900/75 backdrop-blur rounded-t-2xl
			${settingsOpen ? "bottom-0" : "-bottom-full"}
			transition-[bottom] p-5 left-[5%] right-[5%]`}
			>
				<div className={"flex flex-col h-full"}>
					<div
						className={
							"flex flex-col w-[12rem] max-h-full items-stretch mx-auto gap-2 overflow-x-auto p-2"
						}
					>
						<span className={"self-center font-bold size"}>
							Client settings
						</span>

						{settings.map((setting) => (
							<div
								className={`flex flex-row justify-between min-w-full`}
								key={setting.propertyName}
							>
								<label htmlFor={setting.propertyName}>
									{setting.label}
								</label>
								<span>
									<input
										id={setting.propertyName}
										type={"checkbox"}
										checked={setting.state.current}
										onChange={(event) =>
											setSetting(
												setting,
												event.target.checked
											)
										}
									/>{" "}
								</span>
							</div>
						))}
					</div>

					<span className={"self-center font-bold size"}>
						Tile preview
					</span>
					<div
						className={
							"flex flex-row gap-1 flex-wrap w-[95%] self-center text-center justify-center p-1"
						}
					>
						<Letter
							status={"UNKNOWN"}
							letter={"F"}
							focused={true}
							active={true}
						/>
						<Letter status={"UNKNOWN"} letter={"R"} />
						<Letter
							status={"YELLOW"}
							letter={"I"}
							revealing={true}
						/>
						<Letter status={"GREY"} letter={"E"} revealing={true} />
						<Letter
							status={"GREEN"}
							letter={"N"}
							revealing={true}
						/>
						<Letter
							status={"YELLOW"}
							letter={"D"}
							revealing={true}
						/>
						<Letter status={"GREY"} letter={"L"} revealing={true} />
						<Letter
							status={"GREEN"}
							letter={"E"}
							revealing={true}
						/>
					</div>

					<div className={"flex-grow"} />

					<div>
						<p>
							Friendle, created by{" "}
							<a href={"https://pimanrul.es"} target={"_blank"}>
								pimanrules
							</a>{" "}
							and{" "}
							<a
								href={"https://github.com/jordanel"}
								target={"_blank"}
							>
								Jordan Elliott
							</a>
							. Based on{" "}
							<a
								href={"https://www.powerlanguage.co.uk/wordle/"}
								target={"_blank"}
							>
								Wordle
							</a>{" "}
							(obviously).
						</p>
						<p>
							<a
								href={"mailto:words@pimanrul.es"}
								target={"_blank"}
							>
								Contact us!
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Header;
