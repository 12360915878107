import Guess from "./Guess";
import React from "react";
import { Word } from "../words/Word";

type GameBoardProps = {
	guesses: Word[];
	currentWord?: number;
};

function GameBoard({ guesses, currentWord }: GameBoardProps) {
	return (
		<div>
			{guesses.map((guess, index) => {
				return (
					<Guess
						key={index}
						word={guess}
						targeted={currentWord === index}
						position={index}
					/>
				);
			})}
		</div>
	);
}

export default GameBoard;
