import { useEffect, useState } from "react";
import { Character } from "../words/characters.interface";
import { keyBoardColorMap } from "../util/colorMap";

type KeyboardProps = {
	keyStatuses: { [key: string]: string };
	numberRow?: boolean;
};

function Keyboard({ keyStatuses, numberRow }: KeyboardProps) {
	function pressButton(letter: string) {
		let realLetter = letter;
		if (letter === "⏎") {
			realLetter = "Enter";
		} else if (letter === "⌫") {
			realLetter = "Backspace";
		}
		document.dispatchEvent(
			new KeyboardEvent("keydown", { key: realLetter })
		);
	}

	const [keys, setKeys] = useState<Character[][]>([]);

	useEffect(() => {
		const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
		const letters = [
			["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
			["A", "S", "D", "F", "G", "H", "J", "K", "L"],
			["⏎", "Z", "X", "C", "V", "B", "N", "M", "⌫"],
		];
		if (numberRow) {
			letters.unshift(numbers);
		}

		const keys: Character[][] = [];
		letters.forEach((row) => {
			const keyRow: Character[] = [];
			row.forEach((letter) => {
				const key: Character = {
					letter: letter,
					status: keyStatuses[letter] || "UNKNOWN",
				};
				keyRow.push(key);
			});
			keys.push(keyRow);
		});
		setKeys(keys);
	}, [keyStatuses, numberRow]);

	const specialKeys = ["⏎", "⌫"];
	const normalStyle = "border rounded basis-1/12 m-0.5 aspect-[2/2]";
	const specialStyle = "border rounded basis-2/12 m-0.5 aspect-[4/2]";

	return (
		<div className="w-full justify-center">
			{keys.map((row) => (
				<div
					key={row.map((character) => character.letter).join()}
					className="flex flex-row justify-center"
				>
					{row.map((letter) => (
						<button
							key={letter.letter}
							onClick={() => pressButton(letter.letter || "")}
							className={`border-body ${
								specialKeys.includes(letter.letter || "")
									? specialStyle
									: normalStyle
							} ${keyBoardColorMap[letter.status]} font-tiles`}
						>
							{letter.letter}
						</button>
					))}
				</div>
			))}
		</div>
	);
}

export default Keyboard;
