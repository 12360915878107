import Guess from "../components/Guess";
import { Word } from "../words/Word";
import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "../context/socket";

type ResultsProps = {
	results: { playerUsername: string; score: number }[];
	words: string[];
	isHost: boolean;
	onLobbyJoined: (players: string[], lobbyId?: string, name?: string) => void;
};

function Results({ results, words, isHost, onLobbyJoined }: ResultsProps) {
	const socket = useContext(SocketContext);

	const [players] = useState<{ player: Word; score: number }[]>(
		results.map((result) => ({
			player: Word.fromString(result.playerUsername),
			score: result.score,
		}))
	);

	function returnToLobby() {
		socket.emit("returnToLobby");
	}

	useEffect(() => {
		socket.on("gameTick", (tickEvents) => {
			tickEvents.forEach((event) => {
				if (event.tickType === "RETURNING_TO_LOBBY") {
					onLobbyJoined(
						players.map((result) => result.player.getWord())
					);
				}
			});
		});

		return function cleanup() {
			socket.off("gameTick");
		};
	});

	return (
		<div className="max-w-lg mx-auto p-2">
			<div>
				<h6 className="pb-3">Players</h6>
				{players.map((result) => (
					<div
						key={result.player.getWord()}
						className="flex flex-row pb-2"
					>
						<div className="basis-3/4">
							<Guess word={result.player} />
						</div>
						<span className="basis-1/4 text-left self-center">
							{result.score} pts
						</span>
					</div>
				))}
			</div>
			<div>
				<h6 className="pb-3">Word list</h6>
				{words.map((word) => (
					<div
						key={`word_${word}`}
						className="flex flex-row pb-2 justify-center"
					>
						<div className="basis-3/4 text-center">
							<Guess word={Word.fromString(word)} />
						</div>
					</div>
				))}
			</div>
			{isHost && (
				<button
					onClick={returnToLobby}
					className="disabled:text-gray-600"
				>
					Play Again
				</button>
			)}
		</div>
	);
}

export default Results;
