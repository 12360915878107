import React, { ReactElement } from "react";
import "./App.css";
import FrontPage from "./pages/FrontPage";
import {
	SampleContext,
	samplePlayer,
	socket,
	SocketContext,
} from "./context/socket";
import Lobby from "./pages/Lobby";
import Game from "./pages/Game";
import Results from "./pages/Results";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface AppProps {}

interface AppState {
	page: ReactElement;
	isHost: boolean;
	players: string[];
	name: string;
	lobbyId: string;
}

class App extends React.Component<AppProps, AppState> {
	constructor(props: AppProps) {
		super(props);
		this.state = {
			page: <FrontPage onLobbyJoined={this.onLobbyJoined} />,
			isHost: false,
			players: [],
			name: "",
			lobbyId: "",
		};
	}

	onLobbyJoined = (
		playerList: string[],
		lobbyId: string = this.state.lobbyId,
		playerName: string = this.state.name
	) => {
		let host = this.state.isHost;
		if (!playerList || !playerList.length) {
			playerList = [playerName];
			host = true;
		}
		this.setState({
			isHost: host,
			name: playerName,
			lobbyId: lobbyId,
			page: (
				<Lobby
					lobbyId={lobbyId}
					playerList={playerList}
					isHost={host}
					onGameStart={this.onGameStart}
				/>
			),
		});
	};

	onGameStart = (players: string[]) => {
		this.setState({
			page: (
				<Game
					playerName={this.state.name}
					players={players.filter(
						(player) => player !== this.state.name
					)}
					onGameOver={this.onGameOver}
				/>
			),
			players: players,
		});
	};

	onGameOver = (
		results: { playerUsername: string; score: number }[],
		words: string[]
	) => {
		this.setState({
			page: (
				<Results
					results={results}
					words={words}
					isHost={this.state.isHost}
					onLobbyJoined={this.onLobbyJoined}
				/>
			),
		});
	};

	render() {
		return (
			<SocketContext.Provider value={socket}>
				<SampleContext.Provider value={samplePlayer}>
					<Header />
					<div className="App">{this.state.page}</div>
					<ToastContainer
						position="top-center"
						autoClose={2000}
						hideProgressBar
						toastClassName={"backdrop-blur"}
						theme="dark" //overridden in index.css
					/>
				</SampleContext.Provider>
			</SocketContext.Provider>
		);
	}
}

export default App;
