import Letter from "./Letter";
import { Character } from "../words/characters.interface";
import { Word } from "../words/Word";
import { useEffect, useState } from "react";

type GuessProps = {
	word: Word;
	targeted?: boolean;
	position?: number;
};

function Guess({ word, targeted, position }: GuessProps) {
	let chars: Character[] = Array.from(new Array(5), () => ({
		letter: "",
		status: "UNKNOWN",
	}));
	chars = word.characters.concat(chars).slice(0, word.maxLength);

	let [revealing, setRevealing] = useState<boolean>(false);

	useEffect(() => {
		setRevealing(false);
		let listener = word.reveal.addListener("reveal", () => {
			setRevealing(true);
		});
		return () => {
			listener.remove();
		};
	}, [word]);

	return (
		<span className={`flex flex-row gap-1 justify-center mb-2`}>
			{chars.map((character, index) => (
				<Letter
					key={index}
					letter={character.letter}
					status={character.status}
					focused={targeted}
					revealing={revealing}
					active={targeted && index === word.characters.length}
					animationDelay={index * 150}
					position={
						position !== undefined ? [position, index] : undefined
					}
				/>
			))}
		</span>
	);
}

export default Guess;
