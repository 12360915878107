import Guess from "../components/Guess";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { SocketContext } from "../context/socket";
import { Word } from "../words/Word";
import { isInvalidKey } from "../util/keyUtil";
import Keyboard from "../components/Keyboard";
import { LobbyJoinResult } from "../../../app/types/SocketTypes";
import { toast } from "react-toastify";

type FrontPageProps = {
	onLobbyJoined: (players: string[], lobbyId: string, name: string) => void;
};

function FrontPage({ onLobbyJoined }: FrontPageProps) {
	const socket = useContext(SocketContext);

	enum TypingTarget {
		Name1,
		Code,
		Name2,
	}

	const [typingTarget, setTypingTarget] = useState(TypingTarget.Name1);
	const [name1, setName1] = useState<Word>(new Word());
	const [name2, setName2] = useState<Word>(new Word());
	const [code, setCode] = useState<Word>(new Word());

	useEffect(() => {
		document.getElementById("name-div")?.focus();
	}, []);

	useEffect(() => {
		let keyListener = function (e: KeyboardEvent) {
			if (isInvalidKey(e, true, true)) {
				return;
			}

			if (e.key === "Enter") {
				if (
					code.length() &&
					(typingTarget === TypingTarget.Code ||
						typingTarget === TypingTarget.Name2)
				) {
					joinLobby(name2.getWord(), code.getWord());
				} else if (typingTarget === TypingTarget.Name1) {
					createGame();
				}
				return;
			}

			function updateWord(word: Word, setter: Function) {
				if (e.key === "Backspace" || e.key === "Delete") {
					word.deleteLetter();
					setter(word.clone());
				} else {
					word.addLetter(e.key);
					setter(word.clone());
				}
			}

			switch (typingTarget) {
				case TypingTarget.Name1:
					updateWord(name1, setName1);
					break;
				case TypingTarget.Code:
					updateWord(code, setCode);
					break;
				case TypingTarget.Name2:
					updateWord(name2, setName2);
					break;
			}
		};
		document.addEventListener("keydown", keyListener);
		return () => {
			document.removeEventListener("keydown", keyListener);
		};
	});

	function joinLobby(name: string, lobbyId: string) {
		socket.emit("joinLobby", name, lobbyId, (result: LobbyJoinResult) => {
			if (result.result === "SUCCESS") {
				onLobbyJoined(result.members, lobbyId, name);
			} else {
				if (result.error === "DOESNT_EXIST") {
					toast.error(`Sorry, no lobby with ID ${lobbyId}.`);
				} else if (result.error === "IN_GAME") {
					toast.error(
						`Sorry, lobby ${lobbyId} is in the middle of a game`
					);
				} else if (result.error === "USERNAME_EXISTS") {
					toast.error(
						`Sorry, a user with the name ${name} already exists in lobby ${lobbyId}.`
					);
				}
			}
		});
	}

	function createGame() {
		socket.emit(
			"createLobby",
			name1.getWord(),
			{ timeLimit: 10 * 60 },
			(lobbyId: string) => {
				if (lobbyId) {
					onLobbyJoined([], lobbyId, name1.getWord());
				} else {
					toast.error("Sorry, couldn't create a lobby");
				}
			}
		);
	}

	const keyboard = useMemo(
		() => <Keyboard keyStatuses={{}} numberRow={true} />,
		[]
	);

	function updateTypingTarget(target: TypingTarget) {
		setTypingTarget(target);
	}

	return (
		<div className="max-w-lg mx-auto p-2 flex flex-col h-full">
			<div>
				<h6 className="pb-3">Create a game</h6>
				<div
					className="flex flex-row pb-1 focus:outline-none"
					tabIndex={0}
					onFocus={() => updateTypingTarget(TypingTarget.Name1)}
					id={"name-div"}
				>
					<span className="basis-1/4 self-center">Your name:</span>
					<div className="basis-3/4">
						<Guess
							word={name1}
							targeted={typingTarget === TypingTarget.Name1}
						/>
					</div>
				</div>
				<div>
					<button
						className="p-5 disabled:text-gray-600"
						disabled={!name1.length()}
						onClick={createGame}
					>
						Create and play
					</button>
					{/*<button className="p-5">Create and spectate</button>*/}
				</div>
			</div>
			<hr />
			<div className="flex-grow">
				<h6 className="pb-3">Join a game</h6>
				<div
					className="flex flex-row pb-2 focus:outline-none"
					tabIndex={0}
					onFocus={() => updateTypingTarget(TypingTarget.Code)}
				>
					<span className="basis-1/4 self-center">Game code:</span>
					<div className="basis-3/4">
						<Guess
							word={code}
							targeted={typingTarget === TypingTarget.Code}
						/>
					</div>
				</div>
				<div
					className="flex flex-row pb-2 focus:outline-none"
					tabIndex={0}
					onFocus={() => updateTypingTarget(TypingTarget.Name2)}
				>
					<span className="basis-1/4 self-center">Your name:</span>
					<div className="basis-3/4">
						<Guess
							word={name2}
							targeted={typingTarget === TypingTarget.Name2}
						/>
					</div>
				</div>
				<div>
					<button
						className="p-5 disabled:text-gray-600"
						disabled={!name2.length() || !code.length()}
						onClick={() =>
							joinLobby(name2.getWord(), code.getWord())
						}
					>
						Join
					</button>
					{/*<button className="p-5">Spectate</button>*/}
				</div>
			</div>
			{keyboard}
		</div>
	);
}

export default FrontPage;
