import { letterColorMap } from "../util/colorMap";
import { useContext, useEffect, useState } from "react";
import { SampleContext } from "../context/socket";

type LetterProps = {
	letter?: string;
	status: string;
	focused?: boolean;
	revealing?: boolean;
	active?: boolean;
	animationDelay?: number;
	position?: [number, number];
};

function Letter({
	letter,
	status,
	focused,
	revealing,
	active,
	animationDelay,
	position,
}: LetterProps) {
	let [askewAngle, setAskewAngle] = useState<number>(0);
	let [xOffset, setXOffset] = useState<number>(0);
	let [yOffset, setYOffset] = useState<number>(0);
	let [animationState, setAnimationState] = useState<string>("none");
	let [displayedStatus, setDisplayedStatus] = useState<string>("UNKNOWN");

	const samplePlayer = useContext(SampleContext);

	useEffect(() => {
		setAskewAngle(Math.random() * 6 - 3);
		setXOffset(Math.random() * 3 - 1.5);
		setYOffset(Math.random() * 3 - 1.5);
	}, [letter]);

	useEffect(() => {
		if (revealing) {
			setAnimationState("starting");
			setTimeout(() => {
				setAnimationState("part-1");
			}, animationDelay);
		}
	}, [revealing]);

	useEffect(() => {
		if (status === "UNKNOWN") {
			setDisplayedStatus("UNKNOWN");
		}
	}, [status]);

	function getFocusStyle() {
		if (
			focused ||
			animationState === "starting" ||
			animationState === "part-1"
		) {
			if (letter) {
				return "drop-shadow-entered -translate-x-entered -translate-y-entered";
			}
			return "drop-shadow-focused -translate-x-focused -translate-y-focused";
		}

		return "drop-shadow-unfocused";
	}

	function getAnimationClass() {
		if (animationState === "part-1") {
			return "animate-first-half";
		}
		if (animationState === "part-2") {
			return "animate-second-half";
		}
		return "";
	}

	function onAnimationEnd() {
		if (animationState === "part-1") {
			setDisplayedStatus(status);
			setAnimationState("part-2");
		} else {
			setAnimationState("none");
			if (position) {
				if (status === "GREY") {
					samplePlayer.getRandomMissSample().play();
				} else if (status === "YELLOW") {
					samplePlayer.getCloseSample(position).play();
				} else if (status === "GREEN") {
					samplePlayer.getHitSample(position).play();
				}
			}
		}
	}

	return (
		<span
			className={`border-1 rounded basis-1/5 max-w-12
				 aspect-square text-3xl font-tiles leading-10 
				 transition
				 ${letterColorMap[displayedStatus]}
				 ${getFocusStyle()}
				 ${active ? "animate-pulse" : ""}
				 ${getAnimationClass()}
				 `}
			style={
				displayedStatus !== "UNKNOWN"
					? {
							transform: `translate(calc(${xOffset}px * var(--cant-multiplier)), calc(${yOffset}px * var(--cant-multiplier))) rotate(calc(${askewAngle}deg * var(--cant-multiplier)))`,
					  }
					: {}
			}
			onAnimationEnd={onAnimationEnd}
		>
			{letter ? letter.toUpperCase() : ""}
		</span>
	);
}

export default Letter;
