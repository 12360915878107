import GameBoard from "../components/GameBoard";
import { useContext, useEffect, useState } from "react";
import { SampleContext, SocketContext } from "../context/socket";
import { Word } from "../words/Word";
import { isInvalidKey } from "../util/keyUtil";
import Keyboard from "../components/Keyboard";
import { toast } from "react-toastify";

type GameProps = {
	playerName: string;
	players: string[];
	onGameOver: (
		results: { playerUsername: string; score: number }[],
		words: string[]
	) => void;
};

type PlayerData = {
	playerName: string;
	score: number;
	words: Word[];
	currentWord: number;
};

function Game({ playerName, players, onGameOver }: GameProps) {
	const socket = useContext(SocketContext);
	const samplePlayer = useContext(SampleContext);

	const [timer, setTimer] = useState<number>();
	const [score, setScore] = useState<number>(0);
	const [typingDisabled, setTypingDisabled] = useState<boolean>(false);
	const [words, setWords] = useState<Word[]>(
		Array.from(new Array(6), () => new Word())
	);
	const [currentWord, setCurrentWord] = useState<number>(0);
	const [keyStatuses, setKeyStatuses] = useState({});
	const [playerData, setPlayerData] = useState<PlayerData[]>([]);

	document.onkeydown = function (e) {
		if (typingDisabled || isInvalidKey(e, false, true)) {
			return;
		}

		if (e.key === "Enter") {
			if (!words[currentWord].isFull()) {
				return;
			}

			socket.emit(
				"submitGuess",
				words[currentWord].getWord(),
				(guessResult, gainedScore, scoreChanged, newScore) => {
					if (guessResult === "NOT_A_WORD") {
						toast(`"${words[currentWord].getWord()}" isn't a word`);
						return;
					}
					if (currentWord === 5) {
						setTypingDisabled(true);
					}

					const newWord = words[currentWord].clone();
					newWord.updateWithStatus(guessResult);
					const newWords = words.slice();
					newWords[currentWord] = newWord;
					setWords(newWords);
					if (scoreChanged) {
						setScore(newScore);
					}
					setCurrentWord(currentWord + 1);

					const newKeyStatuses: { [key: string]: string } = {
						...keyStatuses,
					};
					newWord.characters.forEach((character) => {
						const letter = character.letter?.toUpperCase() || "";
						if (character.status === "GREEN") {
							newKeyStatuses[letter] = character.status;
						} else if (
							character.status === "YELLOW" &&
							newKeyStatuses[letter] !== "GREEN"
						) {
							newKeyStatuses[letter] = character.status;
						} else if (
							character.status === "GREY" &&
							(!newKeyStatuses[letter] ||
								newKeyStatuses[letter] === "UNKNOWN")
						) {
							newKeyStatuses[letter] = character.status;
						}
					});
					setKeyStatuses(newKeyStatuses);
				}
			);
		} else if (e.key === "Backspace" || e.key === "Delete") {
			words[currentWord].deleteLetter();
		} else {
			words[currentWord].addLetter(e.key);
		}

		const newWords = words.slice();
		newWords[currentWord] = words[currentWord];
		setWords(newWords);
	};

	function resetBoard() {
		setWords(Array.from(new Array(6), () => new Word()));
		setCurrentWord(0);
		setTypingDisabled(false);
		setKeyStatuses({});
	}

	function formatTime(time: number | undefined): string {
		if (time) {
			let minutes = Math.floor(time / 60);
			let seconds = time % 60;
			return `${minutes}:${seconds.toString().padStart(2, "0")}`;
		} else {
			return "Game start!";
		}
	}

	function formatScore(score: number | undefined): string {
		if (score) {
			return score >= 0 ? score.toString() : `(${score})`;
		} else {
			return "0";
		}
	}

	useEffect(() => {
		setPlayerData(
			players.map((player) => {
				return {
					playerName: player,
					score: 0,
					words: Array.from(new Array(6), () => new Word()),
					currentWord: 0,
				};
			})
		);
	}, [players]);

	useEffect(() => {
		socket.on("gameTick", (tickEvents) => {
			tickEvents.forEach((event) => {
				switch (event.tickType) {
					case "TIMER_UPDATE":
						if (event.timeRemaining % 60 === 4) {
							samplePlayer.getMinuteTicker().play();
						}
						setTimer(event.timeRemaining);
						break;
					case "NEW_WORD":
						if (event.username.toUpperCase() === playerName) {
							resetBoard();
						} else {
							setPlayerData((currentData) => {
								const playerIndex = currentData.findIndex(
									(data) =>
										data.playerName ===
										event.username.toUpperCase()
								);
								if (playerIndex !== -1) {
									const player = currentData[playerIndex];
									const newPlayer = {
										...player,
										words: Array.from(
											new Array(6),
											() => new Word()
										),
										currentWord: 0,
									};
									const newPlayerData = currentData.slice();
									newPlayerData[playerIndex] = newPlayer;
									return newPlayerData;
								}
								return currentData;
							});
						}
						break;
					case "PLAYER_GUESS":
						setPlayerData((currentData: PlayerData[]) => {
							const playerIndex = currentData.findIndex(
								(data) =>
									data.playerName ===
									event.guessingPlayerUsername.toUpperCase()
							);
							if (playerIndex !== -1) {
								const player = currentData[playerIndex];
								const guessedWord =
									player.words[player.currentWord].clone();
								guessedWord.updateWithStatus(
									event.guessingPlayerResult
								);
								const newWords = player.words.slice();
								newWords[player.currentWord] = guessedWord;
								const newPlayer = {
									...player,
									words: newWords,
									currentWord: player.currentWord + 1,
								};
								const newPlayerData = currentData.slice();
								newPlayerData[playerIndex] = newPlayer;
								return newPlayerData;
							}
							return currentData;
						});
						break;
					case "PLAYER_SCORE":
						setPlayerData((currentData: PlayerData[]) => {
							const playerIndex1 = currentData.findIndex(
								(data) =>
									data.playerName ===
									event.scoringPlayerUsername.toUpperCase()
							);
							if (playerIndex1 !== -1) {
								const player = currentData[playerIndex1];
								const newPlayer = {
									...player,
									score: event.scoringPlayerScore,
								};
								const newPlayerData = currentData.slice();
								newPlayerData[playerIndex1] = newPlayer;
								return newPlayerData;
							}
							return currentData;
						});
						break;
					case "GAME_OVER":
						setTypingDisabled(true);
						toast("PENCILS DOWN!");
						break;
					case "GAME_RESULTS":
						onGameOver(event.results, event.wordList);
						break;
				}
			});
		});

		return function cleanup() {
			socket.off("gameTick");
		};
	});

	return (
		<div className="max-w-lg mx-auto p-2 flex flex-col h-full">
			<p className="justify-center text-2xl pb-2">
				{formatTime(timer)} - {formatScore(score)} points
			</p>
			<div className="flex flex-row justify-center gap-3 pb-5 overflow-x-auto">
				{playerData.map((player) => (
					<div
						key={player.playerName}
						className="basis-1/4 bg-gray-900/25 px-2 rounded-md drop-shadow"
					>
						<p className="pb-1">
							{player.playerName}: {player.score} pts
						</p>
						<GameBoard
							guesses={player.words}
							currentWord={player.words.findIndex(
								(word) => !word.length()
							)}
						/>
					</div>
				))}
			</div>
			<div className="flex-grow">
				<GameBoard guesses={words} currentWord={currentWord} />
			</div>
			<Keyboard keyStatuses={keyStatuses} numberRow={false} />
		</div>
	);
}

export default Game;
